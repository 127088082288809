<template>
    <v-container class="fullpage d-flex">
        <v-row align="center" justify="center">
            <v-col cols="12" lg="6">
                <h1 class="text-h2 text-lg-h1 mb-8">{{ $t('terms.title[0]') }} <br> {{ $t('terms.title[1]') }}</h1>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[0]') }}</h2>
                    <p>{{ $t('terms.p[1]') }}</p>
                    <p>{{ $t('terms.p[2]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[3]') }}</h2>
                    <p>{{ $t('terms.p[4]') }}</p>
                    <p>{{ $t('terms.p[5]') }}</p>
                    <p>{{ $t('terms.p[6]') }}</p>
                    <p>{{ $t('terms.p[7]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[8]') }}</h2>
                    <p>{{ $t('terms.p[9]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[10]') }}</h2>
                    <p>{{ $t('terms.p[11]') }}</p>
                
                <h1 class="text-h2 text-lg-h1 mt-16">{{ $t('terms.title[2]') }} <br> {{ $t('terms.title[3]') }}</h1><br>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[12]') }}</h2>
                    <p>{{ $t('terms.p[13]') }}
                        <ul>
                            <li>{{ $t('terms.p[14]') }}</li>
                            <li>{{ $t('terms.p[15]') }}</li>
                            <li>{{ $t('terms.p[16]') }}</li>
                        </ul>
                        {{ $t('terms.p[17]') }} </p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[18]') }}</h2>
                    <p>{{ $t('terms.p[19]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[20]') }}</h2>
                    <p>{{ $t('terms.p[21]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[22]') }}</h2>
                    <p>{{ $t('terms.p[23]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[24]') }}</h2>
                    <p>{{ $t('terms.p[25]') }}</p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[26]') }}</h2>
                    <p>{{ $t('terms.p[27]') }}</p>
                    <p><strong>{{ $t('terms.p[28]') }} <br> {{ $t('terms.p[29]') }}</strong></p>
                <h2 class="text-h5 font-weight-bold mb-2 accent--text">{{ $t('terms.p[30]') }}</h2>
                    <p>{{ $t('terms.p[31]') }}</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'AllWriteReserved',
}
</script>